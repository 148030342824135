import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'
import { Separator } from 'components/separator'
import { Heading2, Heading3, ParagraphText } from 'components/text'
import Section from 'components/section'
import { MainContainer, ImageWrapper } from 'components/container'
import { Spacing, Theme } from '../../constants/variables'
import Metadata from '../../constants/metadata'

function serviceItem(props, item, index) {
  const ServiceElement = styled.div`
      padding: ${Theme.spacing.four};
      border-radius: ${Theme.spacing.two};
      background-color: ${Theme.color.secondary};
      &:hover {
        background-color: rbga(1,1,1, .75);
        box-shadow: 0 20px 100px rgb(34, 46, 65);
        transform: translateY(-10px);
      }
  `
  const ServiceList = styled.ul`
      padding: 0;
      margin: ${Theme.spacing.two} 0 0 0;
  `
  const ServiceListElement = styled.li`
      list-style: none;
      color: ${Theme.color.grayLight};
      margin: 5px 0px;
      &::before {
          display: inline-block;
          content: '';
          border-radius: 2.5px;
          height: 5px;
          width: 5px;
          margin-right: 10px;
          background-color: ${Theme.color.primary};
          position: relative;
          bottom: 2.5px;
      }
  `
  const margin = Theme.spacing.two
  const styles = index === 0 ? {} : { marginTop: margin, marginBottom: margin }
  const illustrationStyles = {
    height: 'auto',
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px'
  }
  const textCols = 6
  const imageCols = 6

  // Services: Full Page
  return (<Col md={12} key={`ServiceCol_${index}`}>
    <AnimationContainer animation="fadeInLeft" delay={200} key={`ServiceContainer_${index}`}>
      <ServiceElement key={`ServiceElement_${index}`} style={styles}>
        {index % 2 === 0 ?
          (<Row>
            <Col md={textCols}>
              <Heading2 style={{ color: Theme.color.white }}>
                {item.header}
              </Heading2>
              <ParagraphText>{item.description}</ParagraphText>
              <Separator />
              {props.renderFullPage ? (
                <ServiceList>
                  {item.list.map((text, i) => {
                    return (<ServiceListElement key={i}>{text}</ServiceListElement>)
                  })}
                </ServiceList>
              ) : ('')}
            </Col>
            <Col md={imageCols}>
              <ImageWrapper>
                {item.illustration('', illustrationStyles)}
              </ImageWrapper>
            </Col>
          </Row>
          ) :
          (
            <Row>
              <Col md={imageCols}>
                <ImageWrapper>
                  {item.illustration('', illustrationStyles)}
                </ImageWrapper>
              </Col>
              <Col md={textCols}>
                <Heading2 style={{ color: Theme.color.white }}>
                  {item.header}
                </Heading2>
                <ParagraphText>{item.description}</ParagraphText>
                <Separator />
                {props.renderFullPage ? (
                  <ServiceList>
                    {item.list.map((text, i) => {
                      return (<ServiceListElement key={i}>{text}</ServiceListElement>)
                    })}
                  </ServiceList>
                ) : ('')}
              </Col>
            </Row>
          )
        }
      </ServiceElement>
    </AnimationContainer>
  </Col>
  )
}

class ServicesPage extends React.Component {
  shouldComponentUpdate() { return false }

  renderPageTitle() {
    return (
      <div>
        <AnimatedHeading text={Metadata.Services.header} /><Separator marginBottom={4} />
        <Heading3 style={{ marginBottom: Spacing.four }}>{Metadata.Services.subheader}</Heading3>
      </div>
    )
  }

  renderTechnologiesTitle() {
    return (<div><AnimatedHeading text="Technologies & Platforms" /><Separator marginBottom={4} /></div>)
  }

  renderTechnologies() {
    return (
      <Container style={{ marginTop: Theme.spacing.max }}>
        {this.renderTechnologiesTitle()}
        <ParagraphText>We use the leading technologies to build your solution.</ParagraphText>
        <Row style={{ marginTop: Theme.spacing.two }}>
          <Col md={4}>
            <Heading3>MOBILE</Heading3>
            <div>
              <ul>
                <li>iPhone, iPad Apps (Swift, Objective-C)</li>
                <li>Android Apps (Kotlin, Java)</li>
                <li>React Native Apps</li>
              </ul>
            </div>
          </Col>
          <Col md={4}>
            <Heading3>WEB</Heading3>
            <div>
              <ul>
                <li>JavaScript / Node.js </li>
                <li>React</li>
                <li>JAMStack / Gatsby / Sanity</li>
                <li>Shopify, WordPress, Squarespace</li>
              </ul>
            </div>
          </Col>
          <Col md={4}>
            <Heading3>API</Heading3>
            <div>
              <ul>
                <li>Python, Django</li>
                <li>Node.js</li>
                <li>Swift Server</li>
              </ul>
            </div>
          </Col>
          <Col md={4}>
            <Heading3>TOOLS</Heading3>
            <div>
              <ul>
                <li>AWS</li>
                <li>GraphQL</li>
                <li>Swagger</li>
                <li>Netlify</li>
                <li>Firebase</li>
                <li>Heroku</li>
              </ul>
            </div>
          </Col>
          <Col md={4}>
            <Heading3>DATABASES</Heading3>
            <div>
              <ul>
                <li>Postgres</li>
                <li>MySQL</li>
                <li>SQL Server</li>
                <li>Sqlite</li>
                <li>MongoDB</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  render() {
    const itemsJSX = Metadata.Services.all.map((item, index) => {
      return serviceItem(this.props, item, index)
    })
    const styles = { paddingTop: Theme.spacing.eight, backgroundColor: Theme.color.dark }
    return (
      <Section id="services" style={styles}>
        <MainContainer>
          <Container>
            {this.renderPageTitle()}
            <Row>
              {itemsJSX}
            </Row>
          </Container>
          {this.renderTechnologies()}
        </MainContainer>
      </Section>
    )
  }
}

export default props => (
  <ServicesPage render={() => <ServicesPage {...props} />} />
)